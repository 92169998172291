<template>
  <v-container fill-height fluid>
    <v-dialog v-model="newUserDialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Password change required
        </v-card-title>

        <v-card-text class="mt-2">
          This is your first time logging in to the portal. Password change is required.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            text
            @click="changePassword"
          >
            Change Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card outlined dark class="elevation-12">
          <v-toolbar extended extension-height="30" dark color="secondary">
            <v-toolbar-title>
              <v-img
                max-height="150"
                max-width="130"
                src="@/assets/eventPosLogo.png"
              />
            </v-toolbar-title>
            <template v-slot:extension>
              <span class="text-caption">
                <v-icon small class="mr-1">mdi-information</v-icon>
                Please enter your login credentials
              </span>
            </template>
          </v-toolbar>

          <v-card-text>
            <v-form>
              <v-text-field v-model="username" prepend-icon="mdi-account" name="username" label="Username" type="text" @keydown.enter="loginPressed"></v-text-field>
              <v-text-field v-model="password" id="password" prepend-icon="mdi-lock" name="password" label="Password" type="password" @keydown.enter="loginPressed"></v-text-field>
            </v-form>
            <a id="forgotPassword" class="text-caption info--text" @click="gotoCognitoHostedUI('forgotPassword')"> Forgot your password? </a>

            <div class="my-6 text-body-2" v-if="errorMessage">
              <v-icon medium color="error">mdi-alert</v-icon> {{errorMessage}}
            </div>
          </v-card-text>

          <v-card-actions>
            <v-btn large class="ml-2 mb-2" color="secondary" @click="loginPressed" :loading="loading">
              <v-icon left dense >mdi-login</v-icon>
              Login</v-btn>
          </v-card-actions>

        </v-card>
        <span class="white--text text-caption"> 
          eventPOS Portal 2.0 (© Technical Services Group Pty Ltd)
        </span>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */

export default {
  data: () => ({
    newUserDialog: false,
    username: '',
    password: '',
    loading: false,
    errorMessage: ''
  }),
  computed: {
  },
  created() {
    // if a user has already logged in
    // send them to home page or passed in redirect url
    // set the authentication header token for all the API calls
    this.$authentication.getAuth().currentAuthenticatedUser().then(user => {
      this.$dashboardAPI.setHeader(user.signInUserSession.idToken.jwtToken)
      this.$router.push({
        path: this.$route.query.redirect || '/'
      })
    }, err => {
      // No user has logged in
    })
  },
  methods: {
    loginPressed() {
      this.loading = true
      this.errorMessage = ''
      // attempt to login using the provided creds
      this.$authentication.signIn(this.username, this.password).then(response => {
        if(response.challengeName == "NEW_PASSWORD_REQUIRED") {
          this.password = ''
          this.loading = false
          this.newUserDialog = true
        } else {
          this.username = ''
          this.password = ''
          this.loading = false
          this.$dashboardAPI.setHeader(response.signInUserSession.idToken.jwtToken)
          this.$router.push({
            path: this.$route.query.redirect || '/'
          })
        }

      }).catch(err => {
        this.loading = false
        this.errorMessage = err.message || err
        // this.username = '' more user friendly to not clear username
        this.password = ''
      })
    },
    changePassword(){
      this.newUserDialog = false
      console.log("test")
      this.gotoCognitoHostedUI('login')
      // var url = `${process.env.VUE_APP_COGNITO_URL}/login?client_id=${process.env.VUE_APP_USER_POOL_CLIENT_ID}&response_type=token&scope=email+openid&redirect_uri=${process.env.VUE_APP_COGNITO_REDIRECT_URI}`
      // console.log(url)
      // window.location.href = url;

    },
    gotoCognitoHostedUI(type) {
      // type is given different UI is presented for forgot password vs login
      console.log(type)
      var url = `${process.env.VUE_APP_COGNITO_URL}/${type}?client_id=${process.env.VUE_APP_USER_POOL_CLIENT_ID}&response_type=token&scope=email+openid&redirect_uri=${process.env.VUE_APP_COGNITO_REDIRECT_URI}`
      console.log(url)
      window.location.href = url;
    }
  }
}
</script>

<style scoped>
#forgotPassword:hover {
  text-decoration: underline
}
</style>